import Header from "modules/gpo/components/Header/Header";
import React,{FC,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { ey_logo } from "assets/images";
import "./Logout.scss";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
type LogoutProps = {
};
const Logout : FC<LogoutProps> = ({
   
  }: LogoutProps) => {
    const navigate = useNavigate();
    const userStateDispatch = useAppDispatch();
    const userState = useAppSelector((state: RootState) => state.user);
   
    useEffect(() => {
        window.history.pushState(null, 'null', window.location.href);
        window.onpopstate = function(event) {
            window.history.pushState(null, 'null', window.location.href);
        };
    },[]);
   
    const navigateToLogin = () =>{
        userStateDispatch(userActions.updateUserState(true));
        window.location.replace(window.location.origin);
    }
    return (
        <div className="logout-container">
             <Header
              showProfileImage={false}
              showSearch={false}
             />
             <div className="logout-container__content">
                <div className="content-wrapper">
                   <div className="content-wrapper__logo">
                      <img className="logo-image" src={ey_logo}/>
                        <div className="logo-name">
                          <span>Talent AI ChatBot</span>
                        </div>
                    </div>
                    <div className="content-wrapper__message">
                       <div className="message-title">
                          <span>Your logout was successful </span>
                       </div>
                       {/* <div className="message-detail">   
                         <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum id diam malesuada, fermentum lorem non, pulvinar metus. 
                        </span>                 
                       </div> */}
                    </div>
                    <div className="content-wrapper__action">
                        <button onClick={() => navigateToLogin()}>LOG IN</button>
                    </div>
                </div>
                
             </div>
        </div>

    );
}

export default Logout;
