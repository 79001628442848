import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGraphUser } from 'common/models';
import { ICurrentUserDetails } from 'common/models/user/current-user-details.model';
import { RequestAccessModel, RequestAccessResponseModel } from 'common/models/user/request-access.model';
import { IUserSessionDetails } from 'common/models/user/user-session-details.model';
import { initialUserState } from './user.state';

const usersSlice = createSlice({
    name: 'users',
    initialState: initialUserState,
    reducers: {
        getUserProfile: (state) => {

        },
        getUserProfileSuccess: (state, { payload }: PayloadAction<IGraphUser>) => {
            state.id = payload.id;
            state.name = payload.displayName;
            state.givenName = payload.givenName;
            state.emailId = payload.mail;
            state.isAuthenticated = true;
            state.isTokenBlacklisted = false;
        },
        getCurrentUserAvatar:(state) => {
        },
        getCurrentUserAvatarSuccess: (state, { payload }: PayloadAction<string>) => {
            state.avatar = payload;
        },
        getUserAvatar:(state) => {
        },
        getUserAvatarSuccess: (state, { payload }: PayloadAction<string>) => {
            state.userAvatar = payload;
        },
        getBotConversationToken:(state) => {
            
        },
        getBotConversationTokenSuccess: (state, { payload }: PayloadAction<any>) => {
            state.botToken = payload;
        },
        getBotConversationTokenFailed: (state, { payload }: PayloadAction<any>) => {
           state.botToken = initialUserState.botToken;
           console.log("Errors in getBotConversationToken",payload);
        },
        setCurrentUserSession:(state) => {

        },
        setCurrentUserSessionSuccess: (state, { payload }: PayloadAction<any>) => {
            payload['hasAccess'] = true;
            state.currentUserSession = payload;
        },
        setCurrentUserSessionFailed: (state, { payload }: PayloadAction<any>) => {
           if(payload[0]?.response?.status==403){
            state.currentUserSession= new IUserSessionDetails('','','',false,[],false);
           }
           else{
            state.currentUserSession = initialUserState.currentUserSession;
           }
           
           console.log("Errors in setCurrentUserSession",payload);
        },
        signOutUser:(state) => {
        },  
        signOutUserSuccess: (state, { payload }: PayloadAction<any>) => {
            state.logoutSuccess = payload;
        },
        signOutUserFailed: (state, { payload }: PayloadAction<any>) => {
           state.logoutSuccess = initialUserState.logoutSuccess;
           console.log("Errors in logout",payload);
        },
        updateUserState : (state, { payload }: PayloadAction<boolean>) => {
            state.loggedIn = payload;
        }, 
        userUserDetailsState : (state,action:PayloadAction<ICurrentUserDetails>) =>{
            state.userDetails = action.payload
        },
        requestAccess:(state, { payload }: PayloadAction<RequestAccessModel>) => {
            
        },
        requestAccessSuccess: (state, { payload }: PayloadAction<RequestAccessResponseModel>) => {
            state.requestAccessResponse = payload;
        },
        requestAccessFailed: (state, { payload }: PayloadAction<any>) => {
            state.requestAccessResponse = undefined;
            console.log("Errors",payload);
        },
    }
});

export const userActions = usersSlice.actions;

export default usersSlice.reducer;
