import React from "react";
import { useNavigate } from "react-router-dom";

import { bot,bot_logo,bot_via, hello_icon } from "assets/images";

import "./Welcome.scss";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <div className="welcome-wrapper">
      <div className="welcome-wrapper__container">
        <header>
          <span>Talent AI ChatBot</span>
        </header>
        <section>
          <div className="notes">
            <h1>Our AI agent will assist with talent related queries.</h1>
            <p>
            It's a GPT-4 powered intelligent chatbot designed to answer your questions.
            </p>
            <button type="button" onClick={() => navigate("chat")}>
                ASK QUESTION
            </button>
          </div>
          {/* <img src={bot_via} /> */}
          <div className="bot-logo">
            <img src={bot_logo} />
            <div className="bot-label">
              <img src={hello_icon} />
              <span className="bot-label__text">Hello, I'm Talent AI ChatBot!</span>
            </div>
          </div> 
        </section>
      </div>
    </div>
  );
};

export default Welcome;
